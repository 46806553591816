<template>
    <div class="w-full px-4 lg:w-10/12 2xl:w-9/12 mx-auto mb-7 lg:mb-10 2xl:mb-14 font-montserrat">
        <div class=" w-11/12 md:w-106 lg:w-8/12 tab-shadow rounded-2xl bg-white-3 relative mx-auto flex z-30">  
            <video class="w-full h-64 md:h-106 mx-auto p-4" controls>
                <source src="../assets/videos/ReferralProgram.mp4" type="video/mp4">
                Your browser does not support the video tag.
            </video>
        </div>

        <div class="w-64 md:w-106 lg:w-8/12 mt-16 mb-10 relative mx-auto flex">
            <img
              src="../assets/images/app-element.png"
              class="absolute z-10 -top-12 md:-top-16 lg:-top-20 2xl:-top-32 -left-16 md:-left-20 lg:-left-40 2xl:-left-52 w-32 md:w-48 lg:w-60 xl:w-64 2xl:w-80">
            <img
              src="../assets/images/app-element.png"
              class="absolute z-10 -bottom-12 md:-top-16 lg:-top-20 2xl:-top-32 -right-16 md:-right-20 lg:-right-40 2xl:-right-52 w-32 md:w-48 lg:w-60 xl:w-64 2xl:w-80 scale">

            <div class="w-full py-4 lg:py-8 px-4 bg-white-3 rounded-2xl mx-auto tab-shadow text-center z-20">
                <h3 class="font-trendsetter text-brown-10 text-5xl lg:text-6xl 2xl:text-7xl shadow">Hey Loyalista!</h3>
                <p class="text-black-3 font-poppins text-sm lg:text-lg 2xl:text-xl">Ready to Save?</p>
                <p class="text-black-3 font-poppins text-sm lg:text-lg 2xl:text-xl">Click, Share, and Score with Our Referral Program Today!</p>
            </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-6 2xl:mt-20">
            <div class="flex gap-3">
                <img src="../assets/icons/download.png" class="w-auto h-14 md:h-20" alt="Download">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">1.</span> Download the App</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">Visit the Play Store (for Android) or the App Store (for iOS) and download our app.</p>
                </div>
            </div>

            <div class="flex gap-3">
                <img src="../assets/icons/click.png" class="w-auto h-14 md:h-20" alt="Click">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">2.</span> Click the Referral Link</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">If you've received a referral link, click on it to get started.(<b>Example Link:</b> Hey Loyalista! Come and join us! 
                        https://lay-bare.com/app/referral?referral_code=MAY24-2095280)
                    </p>
                </div>
            </div>

            <div class="flex gap-3">
                <img src="../assets/icons/register.png" class="w-auto h-14 md:h-20" alt="Register">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">3.</span> Register</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">Open the app and complete the registration process. Fill in your details accurately.</p>
                </div>
            </div>

            <div class="flex gap-3">
                <img src="../assets/icons/verify.png" class="w-auto h-14 md:h-20" alt="Verify">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">4.</span> Verify Your Account</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">Wait for the One-Time Password (OTP) to be sent to the mobile number.</p>
                </div>
            </div>

            <div class="flex gap-3">
                <img src="../assets/icons/discount.png" class="w-auto h-14 md:h-20" alt="Discount">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">5.</span> Receive Your Discount</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">Once your account is verified, you'll automatically receive a discount that you can use towards your first service booking.</p>
                </div>
            </div>

            <div class="flex gap-3">
                <img src="../assets/icons/referee.png" class="w-auto h-14 md:h-20" alt="Referring">
                <div class="content">
                    <h3 class="text-brown-10 font-bold md:text-base lg:text-lg 2xl:text-2xl"><span class="underline">6.</span> Start Referring!</h3>
                    <p class="text-sm align-top lg:text-tiny 2xl:text-lg">After successfully booking, you can start referring friends and family to earn more rewards and discounts!</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
methods: {
    ...mapActions('referral', ['getUuid'])
},

watch: {
    '$route': {
        immediate: true,
        handler(route) {
            if (!route.query.referral_code) return;

            const isAndroid = navigator.userAgent.match(/Android/i);
            const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);

            let referralCode = localStorage.getItem("referral") ? localStorage.getItem("referral") : route.query.referral_code;

            window.location = `lbo://laybare/referral?referral_code=${referralCode}`;


            setTimeout(() => {
                if (isAndroid) {
                    window.location.href = `https://play.google.com/store/apps/details?id=com.lbo.mobile.laybare&referrer=${route.query.referral_code}`;
                }

                if (isIOS) {
                    localStorage.setItem("referral", route.query.referral_code);
                    window.location.href = `https://testflight.apple.com/join/hRjm7PzO?=https://lay-bare.com/app/referral?referral_code=${route.query.referral_code}`;
                }


                if (!document.hidden) {
                    this.$router.push({ path: '/app/referral' });
                }
            }, 1000);

            setTimeout(() => {
                localStorage.removeItem("referral");
            }, 5000)
        }
    }
}
}
</script>

<style scoped>
.tab-shadow {
    box-shadow: 0px 2px 3px 0px #00000040;
}

.shadow {
    text-shadow: 2px 1px #00000040;
}

.scale {
    transform: scale(-1);
}

@media (min-width: 768px) {
    .scale {
        transform: scaleX(-1);
    }
}
</style>